<template>
  <v-card>
    <v-card-title> Filtro do Fornecedor (OC) </v-card-title>
    <v-card-text>
      <v-card>
        <v-card-title>
          {{ filtro.id ? "Editar" : "Nova" }} Filtro
          <v-spacer></v-spacer>
          <v-btn @click="showForm = !showForm" color="warning"
            >{{ showForm ? "Esconder" : "Mostrar" }} Formulário</v-btn
          >
        </v-card-title>
        <v-card-text v-show="showForm">
          <v-row>
            <v-col>
              <v-text-field
                label="Nome do FIltro"
                v-model="filtro.name"
                hint="Nome do Filtro"
                persistent-hint
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-autocomplete
              chips
              clearable
              multiple
              deletable-chips
              small-chips
                v-model="filtro.regexIds"
                :items="getRegexsSelect"
                outlined
                label="Selelcione as REGEX na Ordem a serem executadas"
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete
                v-model="filtro.providerId"
                :items="getProvidersSelect"
                outlined
                label="Selelcione o fornecedor"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-spacer></v-spacer>
            <v-btn color="warning" @click="clearForm">Cancelar</v-btn>
            <v-btn class="mx-2" color="success" @click="save">Salvar</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="my-2">
        <v-card-title>
          Filtros Cadastrados <v-spacer></v-spacer>
          <v-btn @click="$store.dispatch('loadFiltersProviders')"
            >Atualizar</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-simple-table height="500px">
            <thead>
              <tr>
                <th class="text-left">ID</th>
                <th class="text-left">Nome</th>
                <th class="text-left">Regex usados</th>
                <th class="text-left">Fornecedor</th>
                <th class="text-left">Cadastro</th>
                <th class="text-left">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in getFiltersProviders" :key="index">
                <td>{{ item.id }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.regexIds }}</td>
                <td>{{ getProviderNameById(item.providerId) }}</td>
                <td>{{ new Date(item.createdAt).toLocaleString() }}</td>
                <td>
                  <v-icon small @click="del(item)" class="m-1">
                    mdi-trash-can
                  </v-icon>
                  <v-icon small @click="edt(item)"> mdi-lead-pencil </v-icon>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "filtro",
  components: {
  
  },
  data() {
    return {
      showForm: false,
      filtro: {
        buyOrderItems: [{}],
      },
    };
  },
  computed: {
    ...mapGetters([
      "getFiltersProviders",
      "getRegexsSelect",
      "getProvidersSelect",
      "getProviderNameById",
    ]),
  },
  methods: {
    save() {
      this.$store.dispatch("saveFilterProvider", this.filtro);
    },
    clearForm() {
      console.log("clearForm");
      this.filtro = {}
    },
    del(e) {
      this.$store.dispatch("deletFiltersProvider", e);
    },
    edt(e) {
      this.filtro = e;
      this.showForm = true;
    },
    addItem() {
      this.filtro.buyOrderItems.push({});
    },
    delItem(index) {
      this.filtro.buyOrderItems.splice(index, 1);
    },
  },
  mounted() {
    this.$store.dispatch("loadRegexs");
    this.$store.dispatch('loadProviders');
    this.$store.dispatch('loadFiltersProviders');
  },
};
</script>

<style>
</style>